import { createSelector } from 'reselect';

const selectCategoryReducer = (state) => state.categories;

export const selectCategories = createSelector(
  [selectCategoryReducer],
  (categoriesSlice) => categoriesSlice.categories
);

export const selectCategory = (id)=> createSelector(
  [
    selectCategories
  ],
  (categories)=>{
    const matchedCategory = categories.filter( category=> category.id === id);
    if (matchedCategory){
      return matchedCategory[0];
    }
    else{
      return null;
    }
  }
);
