import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from './root-reducer';
import logger from 'redux-logger';

const  middleWares = [process.env.NODE_ENV === 'development' && logger].filter(
  Boolean
);

export const store = configureStore({
  reducer: rootReducer,
  // {serializableCheck: false}
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleWares)
});
