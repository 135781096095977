import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const CategoryPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const Title = styled(Link)`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 25px;
  cursor: pointer;
  &:hover{
    color: #ee2121;
  }
  
   transition: color .2s ease-out;
`;
export const Preview = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

 
