import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductCard from '../../components/product-card/product-card.component';
import { selectCategory } from '../../store/categories/category.selector';
import { CategoryContainer, Title } from './category.styles';

const Category = () => {
  const { category } = useParams();
  const categoriesMap = useSelector(selectCategory(category) );


  return (
    <>
      <Title>{categoriesMap && categoriesMap.title}</Title>
      <CategoryContainer>
        {categoriesMap && categoriesMap.items &&
          categoriesMap.items.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
      </CategoryContainer>
    </>
  );
};

export default Category;
