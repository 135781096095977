import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavigationContainer = styled.div`
  height: 70px;
  width: 100%;
  min-width: 320px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  font-size: 1.1rem;
  font-weight: 500;
`;

export const LogoContainer = styled(Link)`
  height: 100%;
  width: 120px;
  padding: 0;
  img{
    height: 100%;
    border-radius: 50% 50%;
\    border: 1px solid #bbb;
  }
`;

export const NavLinks = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 480px) {
    white-space: nowrap;
    font-size: 1rem;
  }
`;

export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
`;
