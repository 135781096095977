// import { CART_ACTION_TYPES } from './cart.types';
import { createSlice } from '@reduxjs/toolkit';

const CART_INITIAL_STATE = {
  isCartOpen: false,
  cartItems: [],
};

// Returns an array with new item in cart, or updated quantity for existing cart item
const addCartItem = (cartItems, productToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === productToAdd.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.id === productToAdd.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }

  return [...cartItems, { ...productToAdd, quantity: 1 }];
};


// Return an array with the product to remove removed
const removeCartItem = (cartItems, cartItemToRemove) => {
  // find the cart item to remove
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === cartItemToRemove.id
  );

  // check if quantity is equal to 1, if it is remove that item from the cart
  if (existingCartItem.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.id !== cartItemToRemove.id);
  }

  // return back cartitems with matching cart item with reduced quantity
  return cartItems.map((cartItem) =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: CART_INITIAL_STATE,
  reducers: {
    // set cart open status
    setIsCartOpen(state,action){
      state.isCartOpen = action.payload;
    },

    // add an item to cart
    addItemToCart(state, action){
      state.cartItems = addCartItem(state.cartItems, action.payload);
    },

    // remove an item from cart
    removeItemFromCart(state, action){
      state.cartItems = removeCartItem(state.cartItems, action.payload);
    },

    // clear item from cart
    clearItemFromCart(state, action){
      state.cartItems = state.cartItems.filter((cartItem) => cartItem.id !== action.payload.id);
    }

  }
});

export const {setIsCartOpen, addItemToCart, removeItemFromCart, clearItemFromCart} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;

/*
export const cartReducer = (state = CART_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CART_ACTION_TYPES.SET_CART_ITEMS:
      return {
        ...state,
        cartItems: payload,
      };
    case CART_ACTION_TYPES.SET_IS_CART_OPEN:
      return {
        ...state,
        isCartOpen: payload,
      };
    default:
      return state;
  }
};
*/