import DirectoryItem from '../directory-item/directory-item.component';

import { DirectoryContainer } from './directory.styles';

const categories = [
  {
    id: 1,
    title: 'Tokyo Night',
    imageUrl: 'https://hi-ming.io/wp-content/uploads/2023/11/soramachi-skytree1.jpg',
    route: 'shop/tokyo-night',
  },
  {
    id: 2,
    title: 'Hokkaido Night',
    imageUrl: 'https://hi-ming.io/wp-content/uploads/2023/11/sapporo-DSC07458.jpg',
    route: 'shop/hokkaido-night',
  },
  {
    id: 3,
    title: 'Kobe night',
    imageUrl: 'https://hi-ming.io/wp-content/uploads/2023/11/kobe-SDIM3904.jpg',
    route: 'shop/kobe-night',
  },

  {
    id: 4,
    title: 'Kyushu Night',
    imageUrl: 'https://hi-ming.io/wp-content/uploads/2023/11/fukuoka-SDIM2909.jpg',
    route: 'shop/kyushu-night',
  },

  {
    id: 5,
    title: 'Tokyo Panoramic',
    imageUrl: 'https://hi-ming.io/wp-content/uploads/2024/01/tokyo-pano-category-cover.jpg',
    route: 'shop/tokyo-panoramic',
  },
];

const Directory = () => {
  return (
    <DirectoryContainer>
      {categories.map((category) => (
        <DirectoryItem key={category.id} category={category} />
      ))}
    </DirectoryContainer>
  );
};

export default Directory;
