import { useSelector } from 'react-redux';
import { selectCategories } from '../../store/categories/category.selector';
import CategoryPreview from '../../components/category-preview/category-preview.component';
import Spinner from '../../components/spinner/spinner.component';
const CategoriesPreview = () => {
  const categoriesMap = useSelector(selectCategories);

  return (
    categoriesMap.length?
       categoriesMap.map(  (category)=><CategoryPreview key={category.id} id={category.id} title={category.title} products={category.items} /> ) 
    :
      <Spinner />
    
  );
};

export default CategoriesPreview;
