import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import {
  onAuthStateChangedListener,
  createUserDocumentFromAuth,
} from './utils/firebase/firebase.utils';
import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import Authentication from './routes/authentication/authentication.component';
import Shop from './routes/shop/shop.component';
import Checkout from './routes/checkout/checkout.component';
import { setCurrentUser } from './store/user/user.reducer';
import DemoMessage from './components/demo-message/demo-message.component';

import { addCollectionAndDocuments } from './utils/firebase/firebase.utils';
import SHOP_DATA from './shop-data';

const App = () => {
  const dispatch = useDispatch();
/*
  // populate default data
  useEffect( ()=>{
    addCollectionAndDocuments('categories', SHOP_DATA);
  },[]);
*/
  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        createUserDocumentFromAuth(user);
      }

      const pickedUser = user? {accessToken: user.accessToken, email: user.email} : null;
      dispatch(setCurrentUser(pickedUser));
    });

    return unsubscribe;
  }, []);

  return (
    <>
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='shop/*' element={<Shop />} />
        <Route path='auth' element={<Authentication />} />
        <Route path='checkout' element={<Checkout />} />
      </Route>
    </Routes>
    <DemoMessage />
    </>
  );
};

export default App;
