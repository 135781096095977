import { Oval } from 'react-loader-spinner';
import { SpinnerContainer } from './spinner.styles';

const Spinner = ()=>{

    return (
        <SpinnerContainer>
            <Oval
            visible={true}
            height="80"
            width="80"
            color="#777777"
            secondaryColor= "#dddddd"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
        </SpinnerContainer>
    );
}

export default Spinner;