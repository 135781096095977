import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  currentUser: null,
};

// name is also used as the namespace for action type
export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    // setCurrentUser is action function that dispatch action to reducer. The actual reducer will be generated by createSlice
    setCurrentUser(state, action){
      // redux will create a new object to save based on ths mutated object.
      state.currentUser = action.payload;
    }
  }
});


// actions contains all the actions insider userslice object (the reducers object)
export const {setCurrentUser} = userSlice.actions;

// action reducer function generated by createSlice
export const userReducer = userSlice.reducer;