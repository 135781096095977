import styled from "styled-components";

export const DemoMessageContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: content-box;
    background-color: rgba(236, 236, 236, 0.8);
    font-weight: bold;
    padding: 2rem;
    text-align: center;
    z-index: 9999;
`